<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-2 pt-5 pb-5">
            <h1 class="page-header">Dosing</h1>
          </div>
          <div
            v-for="(indication, index) in indications"
            :key="`i_${index}`"
            class="cell small-12 medium-2 pt-5 pb-5">
            <div v-if="indication === 'wamd'">
              <button
                v-if="selectedIndication === indication"
                class="button keep-case expanded blue-active">wAMD</button>
              <button
                v-else
                @click="selectIndication(indication)"
                class="button keep-case expanded blue-ghost">wAMD</button>
            </div>
            <div v-else>
              <button
                v-if="selectedIndication === indication"
                class="button expanded blue-active">{{ indication }}</button>
              <button
                v-else
                @click="selectIndication(indication)"
                class="button expanded blue-ghost">{{ indication }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5">
          <div v-if="hasNew === true" class="cell small-2 medium-offset-2">
            <h6  class="text-center">Year 1 New Patients</h6>
          </div>
          <div v-if="hasNew === true && hasSwitch === true && selectedIndication === 'cnv'" class="cell small-3 medium-offset-1 text-center">
            <h6>Maintenance Dose</h6>
          </div>
          <div v-if="hasNew === true && hasSwitch === true && selectedIndication !== 'cnv'" class="cell small-3 medium-offset-5 text-center">
            <h6>Maintenance Dose</h6>
          </div>
          <div v-if="hasNew === true && hasSwitch === false && timeframe === 3 && selectedIndication === 'cnv'" class="cell small-3 medium-offset-1 text-center">
            <h6>Maintenance Dose</h6>
          </div>
          <div v-if="hasNew === true && hasSwitch === false && timeframe === 3 && selectedIndication !== 'cnv'" class="cell small-3 medium-offset-5 text-center">
            <h6>Maintenance Dose</h6>
          </div>
        </div>
        <div v-for="(drug, index) in drugs" :key="`d_${index}`" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2">
            <button
              class="button blue-active expanded extra-tall">
              <i class="plain" v-if="drug === 'byoviz'">Byooviz</i>
              <i class="plain" v-else>{{ drug }}</i>
              <sup v-if="drug === 'byoviz'">5</sup>
              <sup v-if="drug === 'lucentis'">6</sup>
            </button>
          </div>
          <div v-if="selectedIndication !== 'cnv' && hasNew === true" class="cell small-12 medium-2">
            <label class="text-center compressed">Loading dose<br />(No)</label><br />
            <input v-if="selectedIndication === 'wamd' && drug === 'byoviz'" type="number" class="default center" v-model="byovizWamdLoading">
            <input v-if="selectedIndication === 'dme' && drug === 'byoviz'" type="number" class="default center" v-model="byovizDmeLoading">
            <input v-if="selectedIndication === 'pdr' && drug === 'byoviz'" type="number" class="default center" v-model="byovizPdrLoading">
            <input v-if="selectedIndication === 'rvo' && drug === 'byoviz'" type="number" class="default center" v-model="byovizRvoLoading">
            <input v-if="selectedIndication === 'wamd' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisWamdLoading">
            <input v-if="selectedIndication === 'dme' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisDmeLoading">
            <input v-if="selectedIndication === 'pdr' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisPdrLoading">
            <input v-if="selectedIndication === 'rvo' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisRvoLoading">
            <input v-if="selectedIndication === 'wamd' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarWamdLoading">
            <input v-if="selectedIndication === 'dme' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarDmeLoading">
            <input v-if="selectedIndication === 'pdr' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarPdrLoading">
            <input v-if="selectedIndication === 'rvo' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarRvoLoading">
          </div>
          <div v-if="selectedIndication !== 'cnv' && hasNew === true" class="cell small-12 medium-2">
            <label class="text-center compressed">Maintenance dose<br />(No)</label><br />
            <input v-if="selectedIndication === 'wamd' && drug === 'byoviz'" type="number" class="default center" v-model="byovizWamdMaintenance">
            <input v-if="selectedIndication === 'dme' && drug === 'byoviz'" type="number" class="default center" v-model="byovizDmeMaintenance">
            <input v-if="selectedIndication === 'pdr' && drug === 'byoviz'" type="number" class="default center" v-model="byovizPdrMaintenance">
            <input v-if="selectedIndication === 'rvo' && drug === 'byoviz'" type="number" class="default center" v-model="byovizRvoMaintenance">
            <input v-if="selectedIndication === 'wamd' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisWamdMaintenance">
            <input v-if="selectedIndication === 'dme' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisDmeMaintenance">
            <input v-if="selectedIndication === 'pdr' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisPdrMaintenance">
            <input v-if="selectedIndication === 'rvo' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisRvoMaintenance">
            <input v-if="selectedIndication === 'wamd' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarWamdMaintenance">
            <input v-if="selectedIndication === 'dme' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarDmeMaintenance">
            <input v-if="selectedIndication === 'pdr' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarPdrMaintenance">
            <input v-if="selectedIndication === 'rvo' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarRvoMaintenance">
          </div>
          <div v-if="hasNew === true" class="cell small-12 medium-2">
            <label class="text-center compressed">Total<br />(No)</label><br />
            <input v-if="selectedIndication === 'wamd' && drug === 'byoviz'" type="text" disabled class="fixed center" v-model="byovizWamdTotal">
            <input v-if="selectedIndication === 'dme' && drug === 'byoviz'" type="text" disabled class="fixed center" v-model="byovizDmeTotal">
            <input v-if="selectedIndication === 'pdr' && drug === 'byoviz'" type="text" disabled class="fixed center" v-model="byovizPdrTotal">
            <input v-if="selectedIndication === 'rvo' && drug === 'byoviz'" type="text" disabled class="fixed center" v-model="byovizRvoTotal">
            <input v-if="selectedIndication === 'cnv' && drug === 'byoviz'" type="number" class="default center" v-model="byovizCnvTotal">
            <input v-if="selectedIndication === 'wamd' && drug === 'lucentis'" type="text" disabled class="fixed center" v-model="lucentisWamdTotal">
            <input v-if="selectedIndication === 'dme' && drug === 'lucentis'" type="text" disabled class="fixed center" v-model="lucentisDmeTotal">
            <input v-if="selectedIndication === 'pdr' && drug === 'lucentis'" type="text" disabled class="fixed center" v-model="lucentisPdrTotal">
            <input v-if="selectedIndication === 'rvo' && drug === 'lucentis'" type="text" disabled class="fixed center" v-model="lucentisRvoTotal">
            <input v-if="selectedIndication === 'cnv' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisCnvTotal">
            <input v-if="selectedIndication === 'wamd' && drug === 'biosimilars 2'" type="text" disabled class="fixed center" v-model="biosimilarWamdTotal">
            <input v-if="selectedIndication === 'dme' && drug === 'biosimilars 2'" type="text" disabled class="fixed center" v-model="biosimilarDmeTotal">
            <input v-if="selectedIndication === 'pdr' && drug === 'biosimilars 2'" type="text" disabled class="fixed center" v-model="biosimilarPdrTotal">
            <input v-if="selectedIndication === 'rvo' && drug === 'biosimilars 2'" type="text" disabled class="fixed center" v-model="biosimilarRvoTotal">
            <input v-if="selectedIndication === 'cnv' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarCnvTotal">
          </div>
          <div v-if="timeframe === 3 || hasSwitch === true" class="cell small-12 medium-3 medium-offset-1">
            <label v-if="hasNew === false" class="text-center compressed">Annual maintenance dose switch patients (No)</label>
            <label v-if="timeframe === 1 && hasNew === true" class="text-center compressed">Switch patients<br />(No)</label>
            <label v-if="timeframe === 3 && hasNew === true && hasSwitch === false" class="text-center compressed">New patients year 2 onwards<br />(No)</label>
            <label v-if="timeframe === 3 && hasNew === true && hasSwitch === true" class="text-center compressed">Switch patients and new patients year 2 onwards (No)</label>
            <br />
            <input v-if="selectedIndication === 'wamd' && drug === 'byoviz'" type="number" class="default center" v-model="byovizWamdContinuation">
            <input v-if="selectedIndication === 'dme' && drug === 'byoviz'" type="number" class="default center" v-model="byovizDmeContinuation">
            <input v-if="selectedIndication === 'pdr' && drug === 'byoviz'" type="number" class="default center" v-model="byovizPdrContinuation">
            <input v-if="selectedIndication === 'rvo' && drug === 'byoviz'" type="number" class="default center" v-model="byovizRvoContinuation">
            <input v-if="selectedIndication === 'cnv' && drug === 'byoviz'" type="number" class="default center" v-model="byovizCnvContinuation">
            <input v-if="selectedIndication === 'wamd' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisWamdContinuation">
            <input v-if="selectedIndication === 'dme' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisDmeContinuation">
            <input v-if="selectedIndication === 'pdr' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisPdrContinuation">
            <input v-if="selectedIndication === 'rvo' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisRvoContinuation">
            <input v-if="selectedIndication === 'cnv' && drug === 'lucentis'" type="number" class="default center" v-model="lucentisCnvContinuation">
            <input v-if="selectedIndication === 'wamd' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarWamdContinuation">
            <input v-if="selectedIndication === 'dme' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarDmeContinuation">
            <input v-if="selectedIndication === 'pdr' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarPdrContinuation">
            <input v-if="selectedIndication === 'rvo' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarRvoContinuation">
            <input v-if="selectedIndication === 'cnv' && drug === 'biosimilars 2'" type="number" class="default center" v-model="biosimilarCnvContinuation">
          </div>
          <hr class="wide orange-line" />
        </div>
        <div class="grid-x grid-padding-x pt-50 pb-5">
          <div class="cell small-12 medium-10 pt-4">
            <p class="text-small">
              Patients are assumed to be on therapy for 6 months in the first year and for 12 months in subsequent years. Dosing figures are based on the maximum dose listed in the SmPC.
            </p>
          </div>
          <div class="cell small-12 medium-2 text-right">
            <button @click="goToPatientNumbers" class="button expanded">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Dosing',
  components: {
    Header,
  },
  computed: {
    drugs() {
      return this.$store.state.drugs;
    },
    indications() {
      return this.$store.state.indications;
    },
    timeframe() {
      return this.$store.state.timeframe;
    },
    hasSwitch() {
      let result = false;
      const pTs = this.$store.state.patientTypes;
      for (let i = 0; i < pTs.length; i += 1) {
        if (pTs[i] === 'switch') {
          result = true;
        }
      }
      return result;
    },
    hasNew() {
      let result = false;
      const pTs = this.$store.state.patientTypes;
      for (let i = 0; i < pTs.length; i += 1) {
        if (pTs[i] === 'new') {
          result = true;
        }
      }
      return result;
    },
    byovizWamdTotal() { return (parseInt(this.byovizWamdLoading, 10) + parseInt(this.byovizWamdMaintenance, 10)); },
    byovizDmeTotal() { return (parseInt(this.byovizDmeLoading, 10) + parseInt(this.byovizDmeMaintenance, 10)); },
    byovizPdrTotal() { return (parseInt(this.byovizPdrLoading, 10) + parseInt(this.byovizPdrMaintenance, 10)); },
    byovizRvoTotal() { return (parseInt(this.byovizRvoLoading, 10) + parseInt(this.byovizRvoMaintenance, 10)); },
    lucentisWamdTotal() { return (parseInt(this.lucentisWamdLoading, 10) + parseInt(this.lucentisWamdMaintenance, 10)); },
    lucentisDmeTotal() { return (parseInt(this.lucentisDmeLoading, 10) + parseInt(this.lucentisDmeMaintenance, 10)); },
    lucentisPdrTotal() { return (parseInt(this.lucentisPdrLoading, 10) + parseInt(this.lucentisPdrMaintenance, 10)); },
    lucentisRvoTotal() { return (parseInt(this.lucentisRvoLoading, 10) + parseInt(this.lucentisRvoMaintenance, 10)); },
    biosimilarWamdTotal() { return (parseInt(this.biosimilarWamdLoading, 10) + parseInt(this.biosimilarWamdMaintenance, 10)); },
    biosimilarDmeTotal() { return (parseInt(this.biosimilarDmeLoading, 10) + parseInt(this.biosimilarDmeMaintenance, 10)); },
    biosimilarPdrTotal() { return (parseInt(this.biosimilarPdrLoading, 10) + parseInt(this.biosimilarPdrMaintenance, 10)); },
    biosimilarRvoTotal() { return (parseInt(this.biosimilarRvoLoading, 10) + parseInt(this.biosimilarRvoMaintenance, 10)); },
    byovizWamdLoading: {
      get() {
        return this.$store.state.byovizWamdLoading;
      },
      set(value) {
        this.$store.commit('setByovizWamdLoading', value);
      },
    },
    byovizWamdMaintenance: {
      get() {
        return this.$store.state.byovizWamdMaintenance;
      },
      set(value) {
        this.$store.commit('setByovizWamdMaintenance', value);
      },
    },
    byovizWamdContinuation: {
      get() {
        return this.$store.state.byovizWamdContinuation;
      },
      set(value) {
        this.$store.commit('setByovizWamdContinuation', value);
      },
    },
    byovizDmeLoading: {
      get() {
        return this.$store.state.byovizDmeLoading;
      },
      set(value) {
        this.$store.commit('setByovizDmeLoading', value);
      },
    },
    byovizDmeMaintenance: {
      get() {
        return this.$store.state.byovizDmeMaintenance;
      },
      set(value) {
        this.$store.commit('setByovizDmeMaintenance', value);
      },
    },
    byovizDmeContinuation: {
      get() {
        return this.$store.state.byovizDmeContinuation;
      },
      set(value) {
        this.$store.commit('setByovizDmeContinuation', value);
      },
    },
    byovizPdrLoading: {
      get() {
        return this.$store.state.byovizPdrLoading;
      },
      set(value) {
        this.$store.commit('setByovizPdrLoading', value);
      },
    },
    byovizPdrMaintenance: {
      get() {
        return this.$store.state.byovizPdrMaintenance;
      },
      set(value) {
        this.$store.commit('setByovizPdrMaintenance', value);
      },
    },
    byovizPdrContinuation: {
      get() {
        return this.$store.state.byovizPdrContinuation;
      },
      set(value) {
        this.$store.commit('setByovizPdrContinuation', value);
      },
    },
    byovizRvoLoading: {
      get() {
        return this.$store.state.byovizRvoLoading;
      },
      set(value) {
        this.$store.commit('setByovizRvoLoading', value);
      },
    },
    byovizRvoMaintenance: {
      get() {
        return this.$store.state.byovizRvoMaintenance;
      },
      set(value) {
        this.$store.commit('setByovizRvoMaintenance', value);
      },
    },
    byovizRvoContinuation: {
      get() {
        return this.$store.state.byovizRvoContinuation;
      },
      set(value) {
        this.$store.commit('setByovizRvoContinuation', value);
      },
    },
    byovizCnvTotal: {
      get() {
        return this.$store.state.byovizCnvTotal;
      },
      set(value) {
        this.$store.commit('setByovizCnvTotal', value);
      },
    },
    byovizCnvContinuation: {
      get() {
        return this.$store.state.byovizCnvContinuation;
      },
      set(value) {
        this.$store.commit('setByovizCnvContinuation', value);
      },
    },
    lucentisWamdLoading: {
      get() {
        return this.$store.state.lucentisWamdLoading;
      },
      set(value) {
        this.$store.commit('setLucentisWamdLoading', value);
      },
    },
    lucentisWamdMaintenance: {
      get() {
        return this.$store.state.lucentisWamdMaintenance;
      },
      set(value) {
        this.$store.commit('setLucentisWamdMaintenance', value);
      },
    },
    lucentisWamdContinuation: {
      get() {
        return this.$store.state.lucentisWamdContinuation;
      },
      set(value) {
        this.$store.commit('setLucentisWamdContinuation', value);
      },
    },
    lucentisDmeLoading: {
      get() {
        return this.$store.state.lucentisDmeLoading;
      },
      set(value) {
        this.$store.commit('setLucentisDmeLoading', value);
      },
    },
    lucentisDmeMaintenance: {
      get() {
        return this.$store.state.lucentisDmeMaintenance;
      },
      set(value) {
        this.$store.commit('setLucentisDmeMaintenance', value);
      },
    },
    lucentisDmeContinuation: {
      get() {
        return this.$store.state.lucentisDmeContinuation;
      },
      set(value) {
        this.$store.commit('setLucentisDmeContinuation', value);
      },
    },
    lucentisPdrLoading: {
      get() {
        return this.$store.state.lucentisPdrLoading;
      },
      set(value) {
        this.$store.commit('setLucentisPdrLoading', value);
      },
    },
    lucentisPdrMaintenance: {
      get() {
        return this.$store.state.lucentisPdrMaintenance;
      },
      set(value) {
        this.$store.commit('setLucentisPdrMaintenance', value);
      },
    },
    lucentisPdrContinuation: {
      get() {
        return this.$store.state.lucentisPdrContinuation;
      },
      set(value) {
        this.$store.commit('setLucentisPdrContinuation', value);
      },
    },
    lucentisRvoLoading: {
      get() {
        return this.$store.state.lucentisRvoLoading;
      },
      set(value) {
        this.$store.commit('setLucentisRvoLoading', value);
      },
    },
    lucentisRvoMaintenance: {
      get() {
        return this.$store.state.lucentisRvoMaintenance;
      },
      set(value) {
        this.$store.commit('setLucentisRvoMaintenance', value);
      },
    },
    lucentisRvoContinuation: {
      get() {
        return this.$store.state.lucentisRvoContinuation;
      },
      set(value) {
        this.$store.commit('setLucentisRvoContinuation', value);
      },
    },
    lucentisCnvTotal: {
      get() {
        return this.$store.state.lucentisCnvTotal;
      },
      set(value) {
        this.$store.commit('setLucentisCnvTotal', value);
      },
    },
    lucentisCnvContinuation: {
      get() {
        return this.$store.state.lucentisCnvContinuation;
      },
      set(value) {
        this.$store.commit('setLucentisCnvContinuation', value);
      },
    },
    biosimilarWamdLoading: {
      get() {
        return this.$store.state.biosimilarWamdLoading;
      },
      set(value) {
        this.$store.commit('setBiosimilarWamdLoading', value);
      },
    },
    biosimilarWamdMaintenance: {
      get() {
        return this.$store.state.biosimilarWamdMaintenance;
      },
      set(value) {
        this.$store.commit('setBiosimilarWamdMaintenance', value);
      },
    },
    biosimilarWamdContinuation: {
      get() {
        return this.$store.state.biosimilarWamdContinuation;
      },
      set(value) {
        this.$store.commit('setBiosimilarWamdContinuation', value);
      },
    },
    biosimilarDmeLoading: {
      get() {
        return this.$store.state.biosimilarDmeLoading;
      },
      set(value) {
        this.$store.commit('setBiosimilarDmeLoading', value);
      },
    },
    biosimilarDmeMaintenance: {
      get() {
        return this.$store.state.biosimilarDmeMaintenance;
      },
      set(value) {
        this.$store.commit('setBiosimilarDmeMaintenance', value);
      },
    },
    biosimilarDmeContinuation: {
      get() {
        return this.$store.state.biosimilarDmeContinuation;
      },
      set(value) {
        this.$store.commit('setBiosimilarDmeContinuation', value);
      },
    },
    biosimilarPdrLoading: {
      get() {
        return this.$store.state.biosimilarPdrLoading;
      },
      set(value) {
        this.$store.commit('setBiosimilarPdrLoading', value);
      },
    },
    biosimilarPdrMaintenance: {
      get() {
        return this.$store.state.biosimilarPdrMaintenance;
      },
      set(value) {
        this.$store.commit('setBiosimilarPdrMaintenance', value);
      },
    },
    biosimilarPdrContinuation: {
      get() {
        return this.$store.state.biosimilarPdrContinuation;
      },
      set(value) {
        this.$store.commit('setBiosimilarPdrContinuation', value);
      },
    },
    biosimilarRvoLoading: {
      get() {
        return this.$store.state.biosimilarRvoLoading;
      },
      set(value) {
        this.$store.commit('setBiosimilarRvoLoading', value);
      },
    },
    biosimilarRvoMaintenance: {
      get() {
        return this.$store.state.biosimilarRvoMaintenance;
      },
      set(value) {
        this.$store.commit('setBiosimilarRvoMaintenance', value);
      },
    },
    biosimilarRvoContinuation: {
      get() {
        return this.$store.state.biosimilarRvoContinuation;
      },
      set(value) {
        this.$store.commit('setBiosimilarRvoContinuation', value);
      },
    },
    biosimilarCnvTotal: {
      get() {
        return this.$store.state.biosimilarCnvTotal;
      },
      set(value) {
        this.$store.commit('setBiosimilarCnvTotal', value);
      },
    },
    biosimilarCnvContinuation: {
      get() {
        return this.$store.state.biosimilarCnvContinuation;
      },
      set(value) {
        this.$store.commit('setBiosimilarCnvContinuation', value);
      },
    },
  },
  data() {
    return {
      selectedIndication: '',
    };
  },
  methods: {
    goToPatientNumbers() {
      this.$store.commit('setByovizYearOneWamd', this.byovizWamdTotal);
      this.$store.commit('setByovizContinuationWamd', this.byovizWamdContinuation);
      this.$store.commit('setByovizYearOneDme', this.byovizDmeTotal);
      this.$store.commit('setByovizContinuationDme', this.byovizDmeContinuation);
      this.$store.commit('setByovizYearOnePdr', this.byovizPdrTotal);
      this.$store.commit('setByovizContinuationPdr', this.byovizPdrContinuation);
      this.$store.commit('setByovizYearOneRvo', this.byovizRvoTotal);
      this.$store.commit('setByovizContinuationRvo', this.byovizRvoContinuation);
      this.$store.commit('setByovizYearOneCnv', this.byovizCnvTotal);
      this.$store.commit('setByovizContinuationCnv', this.byovizCnvContinuation);
      this.$store.commit('setLucentisYearOneWamd', this.lucentisWamdTotal);
      this.$store.commit('setLucentisContinuationWamd', this.lucentisWamdContinuation);
      this.$store.commit('setLucentisYearOneDme', this.lucentisDmeTotal);
      this.$store.commit('setLucentisContinuationDme', this.lucentisDmeContinuation);
      this.$store.commit('setLucentisYearOnePdr', this.lucentisPdrTotal);
      this.$store.commit('setLucentisContinuationPdr', this.lucentisPdrContinuation);
      this.$store.commit('setLucentisYearOneRvo', this.lucentisRvoTotal);
      this.$store.commit('setLucentisContinuationRvo', this.lucentisRvoContinuation);
      this.$store.commit('setLucentisYearOneCnv', this.lucentisCnvTotal);
      this.$store.commit('setLucentisContinuationCnv', this.lucentisCnvContinuation);
      this.$store.commit('setBiosimilarYearOneWamd', this.biosimilarWamdTotal);
      this.$store.commit('setBiosimilarContinuationWamd', this.biosimilarWamdContinuation);
      this.$store.commit('setBiosimilarYearOneDme', this.biosimilarDmeTotal);
      this.$store.commit('setBiosimilarContinuationDme', this.biosimilarDmeContinuation);
      this.$store.commit('setBiosimilarYearOnePdr', this.biosimilarPdrTotal);
      this.$store.commit('setBiosimilarContinuationPdr', this.biosimilarPdrContinuation);
      this.$store.commit('setBiosimilarYearOneRvo', this.biosimilarRvoTotal);
      this.$store.commit('setBiosimilarContinuationRvo', this.biosimilarRvoContinuation);
      this.$store.commit('setBiosimilarYearOneCnv', this.biosimilarCnvTotal);
      this.$store.commit('setBiosimilarContinuationCnv', this.biosimilarCnvContinuation);
      this.$router.push('/patient-numbers');
    },
    selectIndication(indication) {
      this.selectedIndication = indication;
    },
    sendPage() {
      this.$store.commit('setPage', 'dosing');
    },
    setSelectedIndication() {
      // eslint-disable-next-line
      this.selectedIndication = this.$store.state.indications[0];
    },
  },
  mounted() {
    this.setSelectedIndication();
    this.sendPage();
  },
};
</script>
